import { matchPath } from 'react-router-dom';
import { pageRoutes } from '../../constants';

export const isHomePage = () => window.location.pathname === '/';

export const isNewYearTile = () =>
  window.location.pathname.match('/t/new-year-new-you');

export const isEmployersPage = () =>
  window.location.pathname.match('/employers');

export const isCheckoutPage = () => window.location.pathname.match('/checkout');
export const isCarePackagePage = () => window.location.pathname.match('/carepackage');

export const isSearchResultPage = () =>
  matchPath('/f/procedure/:procedureUri/:zipCode', window.location.pathname);

export const isProcedurePage = () =>
  matchPath(pageRoutes.PROCEDURE_PAGE_URL, window.location.pathname);

export const isPriceListPage = () =>
  window.location.pathname.includes('-price-list');

export const isGroupPage = (location) => {
  const urlPath = location.pathname.split('/');
  return (
    ['groups', 'groupOzzie'].indexOf(urlPath[1]) !== -1 &&
    ['admin', 'passive', 'groupCreate', 'groupUpdate'].indexOf(urlPath[2]) ===
      -1
  );
};
